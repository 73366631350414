import React, { useState } from "react";
import "../../assets/css/style.css";
import styled from "styled-components";

import ImageCrop from "./ImageCrop";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
// import bg from "../../assets/images/christ-id-card.jpeg";
import bg from "../../assets/images/id-card-bg.jpg";

function ChristIdCard() {
	const [name, setName] = useState("");
	const [branch, setBranch] = useState("");
	const [year, setYear] = useState("");
	const [dp, setDp] = useState();
	const [isIdcardGenerated, setIdcardGenereted] = useState(false);
	const [logo, setLogo] = useState();
	const [subLogo, setSubLogo] = useState();

	// id card generation
	const generateIdCard = () => {
		setIdcardGenereted(true);
		window.scrollTo(0, 0);
		document.getElementById("render").innerHTML = "";
		html2canvas(document.querySelector("#id-card"), {
			scale: 9,
		}).then((canvas) => {
			document.getElementById("render").appendChild(canvas);
		});
		window.scrollTo(
			0,
			document.body.scrollHeight || document.documentElement.scrollHeight
		);
	};

	// canvas in jpeg
	const download = () => {
		const download = document.getElementById("download");
		let image = document
			.querySelector("canvas")
			.toDataURL("image/jpeg")
			.replace("image/pdf", "image/octet-stream");
		download.setAttribute("href", image);
	};

	// canvas to pdf
	// const download = () => {
	// 	const download = document.getElementById("download");
	// 	let image = document
	// 		.querySelector("canvas")
	// 		.toDataURL("image/jpeg")
	// 		.replace("image/pdf", "image/octet-stream");
	// 	var doc = new jsPDF("p", "mm", [53.9988125, 85.997520833]);
	// 	doc.addImage(image, "PNG", 0, 0, 53.9988125, 85.997520833);
	// 	doc.save(`${name}-${branch}-${year}-id-card.pdf`);
	// 	download.setAttribute("href", image);
	// };

	return (
		<App>
			<Container className="main">
				<FormContainer>
					<Form>
						<DetailsHead>Details</DetailsHead>
						<StudentInput>
							<InputName>Name</InputName>
							<InputField
								type="text"
								placeholder="Enter Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</StudentInput>
						<StudentInput>
							<InputName>Branch</InputName>
							<InputField
								type="text"
								placeholder="Enter Branch"
								value={branch}
								onChange={(e) => setBranch(e.target.value)}
							/>
						</StudentInput>
						<StudentInput>
							<InputName>Year</InputName>
							<InputField
								type="text"
								placeholder="Enter Year"
								value={year}
								onChange={(e) => setYear(e.target.value)}
							/>
						</StudentInput>
						<StudentInput>
							<InputName>Photo</InputName>
							<PhotoContainer>
								<ImageCrop setDp={setDp} />
							</PhotoContainer>
						</StudentInput>
						<StudentInput>
							<InputName>Main Logo</InputName>
							<PhotoContainer>
								<Upload
									type="file"
									id="image"
									onChange={(e) => {
										let file = e.target.files[0];
										if (file) {
											let reader = new FileReader();

											reader.onload = () => {
												setLogo(reader.result);
											};

											reader.readAsDataURL(file);
										}
									}}
								/>
							</PhotoContainer>
						</StudentInput>

						<StudentInput>
							<InputName>Bottom Logo</InputName>
							<PhotoContainer>
								<Upload
									type="file"
									id="image"
									onChange={(e) => {
										let file = e.target.files[0];
										if (file) {
											let reader = new FileReader();

											reader.onload = () => {
												setSubLogo(reader.result);
											};

											reader.readAsDataURL(file);
										}
									}}
								/>
							</PhotoContainer>
						</StudentInput>
					</Form>
				</FormContainer>
			</Container>

			<BottomContainer className="bottom">
				<div>
					<IdCard id="id-card">
						<Background src={bg} alt="bg" />

						<MainLogo
							style={{
								position: "absolute",
								left: "0",
								right: "0",
								margin: "auto",
								top: "9.12%",
								width: "105px",
								height: "50px",
								background: "#fff",
							}}
						>
							{logo && <Image src={logo} alt="logo" />}
						</MainLogo>

						<div
							style={{
								position: "absolute",
								left: "0",
								right: "0",
								margin: "auto",
								top: "26.12%",
								width: "113px",
								height: "113px",
								borderRadius: "50%",
								overflow: "hidden",
								background: "#f5f5f5",
							}}
						>
							<Image src={dp} />
						</div>
						<StudentDetails>
							<StudentName>{name}</StudentName>
							<StudentBranch>
								{branch}, {year}
							</StudentBranch>
						</StudentDetails>

						<SubLogo
							style={{
								position: "absolute",
								left: "9px",
								margin: "auto",
								bottom: "4.12%",
								width: "30px",
								height: "30px",
								background: "#f5f5f5",
								padding: "8px",
							}}
						>
							{subLogo && <Image src={subLogo} alt="logo" />}
						</SubLogo>
						<Content
							style={{
								position: "absolute",
								height: "11px",
								right: "19px",
								margin: "auto",
								bottom: "18.5%",
								width: "50px",
								// color: "#2474bd",
								color: "#2e83be",
								background: "#fff",
							}}
						>
							School!
						</Content>
					</IdCard>

					<Generate onClick={generateIdCard}>Generate</Generate>
				</div>
				<div>
					<RenderIdCard
						id="render"
						style={{
							width: "204.09px",
							height: "325.03px",
							backgroundColor: "#f1f1f1",
						}}
					></RenderIdCard>

					{isIdcardGenerated ? (
						<div
							style={{
								width: "auto",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<a
								download={`${name}-${branch}-${year}-id-card.jpeg`}
								id="download"
							>
								<Download onClick={download}>Download</Download>
							</a>
						</div>
					) : null}
					{}
				</div>
			</BottomContainer>
		</App>
	);
}

export default ChristIdCard;

const BottomContainer = styled.div`
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
`;
const Container = styled.div`
	width: 60%;
`;
const Image = styled.img`
	display: block;
	width: 100%;
`;
const App = styled.div`
	display: flex;
	align-items: center;
	margin-top: 30px;
`;
const IdCard = styled.div`
	width: 204.09px;
	height: 365px;
	position: relative;
	margin: 0 auto;
`;
const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
`;
const StudentDetails = styled.div`
	height: 50px;
	width: 90%;
	position: absolute;
	bottom: 25%;

	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	padding: 7px;
	color: #606060;
	font-size: 14px;
	font-family: product_sansbold;
	text-align: center;
	background-color: #fff;
`;
const StudentName = styled.p`
	font-size: 13px;
	margin: 0 auto;
	color: #242424;
	font-weight: 700;
	font-family: product_sansbold;
	text-overflow: ellipsis;
`;
const StudentBranch = styled.span`
	display: inline-block;
	font-size: 8px;
	text-align: center;
	margin: 0 auto;

	color: #242424;
	text-transform: capitalize;
	font-family: product_sansregular;
	text-overflow: ellipsis;
`;
const FormContainer = styled.div`
	display: flex;
	margin: 50px auto;
	border: 1px solid #b5e2f0;
	background-color: #f5fbfd;
	border-radius: 20px;
	padding: 30px;
	flex-direction: column;
	width: 80%;
`;
const DetailsHead = styled.span`
	display: block;
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	margin: 10px 0 20px;
`;
const Form = styled.div`
	text-transform: capitalize;
	font-weight: 500;
	margin: 20px 0;
`;
const StudentInput = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const InputName = styled.p``;
const PhotoContainer = styled.div`
	display: flex;
	align-items: center;
	width: 64%;
`;
const InputField = styled.input`
	padding: 15px;
	width: 64%;
	color: rgb(0, 0, 0);
	font-size: 17px;
	padding-left: 15px;
	caret-color: rgb(92, 198, 106);
	background-color: #fff;
	border: 1px solid #b5e2f0;
	border-radius: 5px;
`;
const Generate = styled.button`
	font-size: 18px;
	font-weight: 500;
	margin: 55px auto 10px;
	display: block;
	padding: 15px 60px;
	background: #9fdbed;
	border: 1px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		background: #b5e2f0;
		border: 1px solid #b5e2f0;
		transition-duration: 0.1s;
	}
`;
const Download = styled.button`
	padding: 20px 100px;
	border-radius: 10px;
	font-size: 18px;
	cursor: pointer;
	font-family: "product_sansbold";
	background: #d1e8e0;
	border: 1px solid #d1e8e0;
	margin: 30px 0 50px;
`;
const Upload = styled.input`
	/* display: block; */
`;
const RenderIdCard = styled.div`
	margin: 0 auto;
`;
const MainLogo = styled.div``;
const SubLogo = styled.div``;
const Content = styled.span`
	font-family: Gabriola;
	font-size: 11px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: left;
`;
