import { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import styled from "styled-components";

function ImageCrop({ setDp }) {
	const [src, setSrc] = useState(null);
	const [crop, setCrop] = useState({ aspect: 1 / 1 });
	const [image, setImage] = useState(null);
	const [output, setOutput] = useState(null);

	const selectImage = (file) => {
		setSrc(URL.createObjectURL(file));
	};

	const cropImageNow = () => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		const pixelRatio = window.devicePixelRatio;
		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;
		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = "high";

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		// Converting to base64
		const base64Image = canvas.toDataURL("image/jpeg");
		setOutput(base64Image);
		setDp(base64Image);
	};

	return (
		<div>
			<center>
				<input
					type="file"
					accept="image/*"
					onChange={(e) => {
						selectImage(e.target.files[0]);
					}}
				/>
				<Container>
					{src && (
						<div>
							<ReactCrop
								src={src}
								onImageLoaded={setImage}
								crop={crop}
								onChange={setCrop}
							/>
							<br />
							<Button onClick={cropImageNow}>Crop Photo</Button>
							<br />
							<br />
						</div>
					)}
				</Container>
				{/* <div>{output && <img src={output} />}</div> */}
			</center>
		</div>
	);
}

export default ImageCrop;

const Container = styled.div`
	margin-top: 10px;
`;
const Button = styled.button`
	padding: 10px;
	margin-top: 10px;
	background: #9fdbed;
	border: 1px solid #fff;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background: #b5e2f0;
		border: 1px solid #b5e2f0;
		transition-duration: 0.1s;
	}
`;
