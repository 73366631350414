import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Home() {
	return (
		<Container>
			<Link to="/talrop-id-card">
				<Goto>Talrop id card</Goto>
			</Link>
			<Link to="/christ-id-card">
				<Goto>Christ id card</Goto>
			</Link>
			<Link to="/onboard-letter">
				<Goto>Talrop onboard letter</Goto>
			</Link>
		</Container>
	);
}

export default Home;

const Container = styled.div`
	width: 70%;
	display: flex;
	justify-content: space-around;
	margin: 100px auto 0;
	padding: 50px;
	border: 1px solid #b5e2f0;
	background-color: #f5fbfd;
	border-radius: 10px;
`;
const Goto = styled.span`
	display: inline-block;
	padding: 10px;
	border: 1px solid #f5ad42;
	background-color: #f0cd99;
	border-radius: 6px;
	color: #5d42f5;
	&:hover {
		background-color: #f0b256;
		border: 1px solid #e68f0e;
	}
`;
