import React, { useState } from "react";
import "../../assets/css/style.css";

import styled from "styled-components";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import bg from "../../assets/images/on-board-letter.jpeg";

function OnboardLetter() {
	const [name, setName] = useState("");
	const [isIdcardGenerated, setIdcardGenereted] = useState(false);

	// id card generation
	const generateIdCard = () => {
		setIdcardGenereted(true);
		window.scrollTo(0, 0);
		document.getElementById("render").innerHTML = "";
		html2canvas(document.querySelector("#onboard-letter"), {
			scale: 9,
		}).then((canvas) => {
			document.getElementById("render").appendChild(canvas);
		});
		window.scrollTo(
			0,
			document.body.scrollHeight || document.documentElement.scrollHeight
		);
	};

	// canvas to pdf
	const download = () => {
		const download = document.getElementById("download");
		let image = document
			.querySelector("canvas")
			.toDataURL("image/png")
			.replace("image/pdf", "image/octet-stream");
		var doc = new jsPDF("p", "mm", "a4");
		var width = doc.internal.pageSize.getWidth();
		var height = doc.internal.pageSize.getHeight();
		doc.addImage(image, "PNG", 0, 0, width, height);
		doc.save(`${name}-onboard-letter.pdf`);
		download.setAttribute("href", doc);
	};

	return (
		<App>
			<Container className="main">
				<FormContainer>
					<Form>
						<DetailsHead>Details</DetailsHead>
						<Input>
							<InputLabel>Name</InputLabel>
							<InputField
								type="text"
								placeholder="Enter Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Input>
					</Form>
				</FormContainer>
			</Container>

			<BottomContainer className="bottom">
				<div>
					<IdCard id="onboard-letter">
						<Background src={bg} alt="bg" />
						<NameTop>{name},</NameTop>
						<NameBottom>{name}!</NameBottom>
						{/* <Content>School</Content> */}
					</IdCard>
					<Generate onClick={generateIdCard}>Generate</Generate>
				</div>
				<div>
					<RenderIdCard
						id="render"
						style={{
							width: "204.09px",
							height: "289px",
							backgroundColor: "#f1f1f1",
						}}
					></RenderIdCard>

					{isIdcardGenerated ? (
						<div
							style={{
								width: "auto",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<div id="download">
								<Download onClick={download}>Download</Download>
							</div>
						</div>
					) : null}
					{}
				</div>
			</BottomContainer>
		</App>
	);
}

export default OnboardLetter;

const BottomContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
`;
const Container = styled.div`
	width: 60%;
	margin: 0 auto;
`;
const Image = styled.img`
	display: block;
	width: 100%;
`;
const App = styled.div``;
const IdCard = styled.div`
	width: 204.09px;
	height: 289px;
	position: relative;
	margin: 0 auto;
`;
const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
`;
const NameTop = styled.span`
	font-size: 9px;
	display: inline-block;
	width: 50%;
	font-family: gordita_regular;
	font-weight: lighter;
	color: #235053;
	position: absolute;
	background: #fff;
	top: 61.9px;
	left: 35px;
	padding-left: 2px;
`;
const NameBottom = styled.span`
	color: #58963f;
	font-size: 8px;
	display: inline-block;
	width: 50%;
	font-family: gordita_regular;
	position: absolute;
	background: #fff;
	bottom: 31.4%;
	left: 98px;
	padding-left: 2px;
`;
const Content = styled.span`
	color: #2e2f2f;
	color: #2d2d2d;
	font-size: 5px;
	font-weight: 400;
	display: inline-block;
	font-family: Lato;
	position: absolute;
	background: #fff;
	top: 81.6px;
	height: 7px;
	right: 16px;
	width: 25px;
`;
const FormContainer = styled.div`
	display: flex;
	margin: 50px auto;
	border: 1px solid #b5e2f0;
	background-color: #f5fbfd;
	border-radius: 20px;
	padding: 30px;
	flex-direction: column;
	width: 80%;
`;
const DetailsHead = styled.span`
	display: block;
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	margin: 10px 0 20px;
`;
const Form = styled.div`
	text-transform: capitalize;
	font-weight: 500;
	margin: 20px 0;
`;
const Input = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const InputLabel = styled.p``;
const InputField = styled.input`
	padding: 15px;
	width: 64%;
	color: rgb(0, 0, 0);
	font-size: 17px;
	padding-left: 15px;
	caret-color: rgb(92, 198, 106);
	background-color: #fff;
	border: 1px solid #b5e2f0;
	border-radius: 5px;
`;
const Generate = styled.button`
	font-size: 18px;
	font-weight: 500;
	margin: 55px auto 10px;
	display: block;
	padding: 15px 60px;
	background: #9fdbed;
	border: 1px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		background: #b5e2f0;
		border: 1px solid #b5e2f0;
		transition-duration: 0.1s;
	}
`;
const Download = styled.button`
	padding: 20px 100px;
	border-radius: 10px;
	font-size: 18px;
	cursor: pointer;
	font-family: "product_sansbold";
	background: #d1e8e0;
	border: 1px solid #d1e8e0;
	margin: 30px 0 50px;
`;
const Upload = styled.input``;
const RenderIdCard = styled.div`
	margin: 0 auto;
`;
