import logo from "./logo.svg";
import "./App.css";

import { Routes, Route } from "react-router-dom";

import IdcardGenerator from "./components/screens/IdcardGenerator";
import ChristIdCard from "./components/screens/ChristIdCard";
import OnboardLetter from "./components/screens/OnboardLetter";
import Home from "./components/screens/Home";

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/talrop-id-card" element={<IdcardGenerator />} />
				<Route path="/christ-id-card" element={<ChristIdCard />} />
				<Route path="/onboard-letter" element={<OnboardLetter />} />
			</Routes>
		</>
	);
}

export default App;
