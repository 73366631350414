import React, { useState } from "react";
import "../../assets/css/style.css";
import styled from "styled-components";

import ImageCrop from "./ImageCrop";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import bg from "../../assets/images/id-card.png";

function IdcardGenerator() {
	const [name, setName] = useState("");
	const [designation, setDesignation] = useState("");
	const [id, setId] = useState("");
	const [dp, setDp] = useState();
	const [bgPosition, setBgPosition] = useState("");
	const [bgSize, setbgSize] = useState("");
	const [isIdcardGenerated, setIdcardGenereted] = useState(false);

	// id card generation
	const generateIdCard = () => {
		setIdcardGenereted(true);
		window.scrollTo(0, 0);
		document.getElementById("render").innerHTML = "";
		html2canvas(document.querySelector("#id-card"), {
			scale: 9,
		}).then((canvas) => {
			document.getElementById("render").appendChild(canvas);
		});
		window.scrollTo(
			0,
			document.body.scrollHeight || document.documentElement.scrollHeight
		);
	};

	// id card downoad
	// const download = () => {
	// 	const download = document.getElementById("download");
	// 	let image = document
	// 		.querySelector("canvas")
	// 		.toDataURL("image/png")
	// 		.replace("image/pdf", "image/octet-stream");
	// 	download.setAttribute("href", image);
	// };

	// canvas to pdf
	const download = () => {
		const download = document.getElementById("download");
		let image = document
			.querySelector("canvas")
			.toDataURL("image/png")
			.replace("image/pdf", "image/octet-stream");
		var doc = new jsPDF("p", "mm", [53.9988125, 85.997520833]);
		doc.addImage(image, "PNG", 0, 0, 53.9988125, 85.997520833);
		doc.save(`${name}-${id}-id-card.pdf`);
		download.setAttribute("href", doc);
	};

	return (
		<App>
			<Container className="main">
				<FormContainer>
					<Form>
						<DetailsHead>Details</DetailsHead>
						<StudentInput>
							<InputName>Name</InputName>
							<InputField
								type="text"
								placeholder="Enter Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</StudentInput>
						<StudentInput>
							<InputName>Designation</InputName>
							<InputField
								type="text"
								placeholder="Enter Designation"
								value={designation}
								onChange={(e) => setDesignation(e.target.value)}
							/>
						</StudentInput>
						<StudentInput>
							<InputName>ID</InputName>
							<InputField
								type="text"
								placeholder="Enter ID"
								value={id}
								onChange={(e) => setId(e.target.value)}
							/>
						</StudentInput>
						<StudentInput>
							<InputName>Photo</InputName>
							<PhotoContainer>
								{/* <Upload
								type="file"
								id="image"
								onChange={(e) => {
									let file = e.target.files[0];
									if (file) {
										let reader = new FileReader();

										reader.onload = () => {
											setDp(reader.result);
										};

										reader.readAsDataURL(file);
									}
								}}
							/> */}
								<ImageCrop setDp={setDp} />
							</PhotoContainer>
						</StudentInput>
						{/* <StudentInput>
                        <InputName>background position</InputName>

                        <InputField
                            type="text"
                            placeholder="position"
                            value={bgPosition}
                            onChange={(e) => setBgPosition(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>background size</InputName>
                        <InputField
                            type="text"
                            placeholder="size"
                            value={bgSize}
                            onChange={(e) => setbgSize(e.target.value)}
                        />
                    </StudentInput> */}
					</Form>
					{/* <Generate onClick={generateIdCard}>Generate</Generate> */}
				</FormContainer>
			</Container>

			<BottomContainer className="bottom">
				<div>
					<IdCard id="id-card">
						<Background src={bg} alt="bg" />

						<div
							style={{
								position: "absolute",
								left: "0",
								right: "0",
								margin: "auto",
								top: "33.36%",
								width: "84px",
								height: "84px",
								// background: `url(${dp}) no-repeat`,
								// backgroundSize: bgSize,
								// backgroundPosition: bgPosition,
								borderRadius: "50%",
								overflow: "hidden",
							}}
						>
							<Image src={dp} />
						</div>
						<StudentDetails>
							<StudentName>{name}</StudentName>
							<StudentDesignation>
								{designation}
							</StudentDesignation>
							<StudentID>ID: {id}</StudentID>
						</StudentDetails>
					</IdCard>
					<Generate onClick={generateIdCard}>Generate</Generate>
				</div>
				<div>
					<RenderIdCard
						id="render"
						style={{
							width: "204.09px",
							height: "325.03px",
							backgroundColor: "#f1f1f1",
						}}
					></RenderIdCard>

					{isIdcardGenerated ? (
						<div
							style={{
								width: "auto",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<div id="download">
								<Download onClick={download}>Download</Download>
							</div>
						</div>
					) : null}
					{}
				</div>
			</BottomContainer>
		</App>
	);
}

export default IdcardGenerator;

const BottomContainer = styled.div`
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
`;
const Container = styled.div`
	width: 60%;
`;
const Image = styled.img`
	display: block;
	width: 100%;
`;
const App = styled.div`
	display: flex;
	align-items: center;
	margin-top: 30px;
`;
const IdCard = styled.div`
	width: 204.09px;
	height: 325.03px;
	position: relative;
	margin: 0 auto;
`;
const Background = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
`;
const StudentDetails = styled.div`
	height: 65px;
	width: 90%;
	position: absolute;
	bottom: 18%;

	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	padding: 10px;
	color: #606060;
	font-size: 14px;
	font-family: product_sansbold;
	text-align: center;
	background-color: #fff;
`;
const StudentName = styled.p`
	font-size: 13px;
	margin: 0 auto;
	color: #242424;
	font-weight: 700;
	font-family: product_sansbold;
	text-overflow: ellipsis;
`;
const StudentDesignation = styled.p`
	font-size: 8px;
	text-align: center;
	margin: 0 auto;

	color: #96ca4b;
	text-transform: capitalize;
	font-weight: 700;
	font-family: product_sansbold;
	text-overflow: ellipsis;
`;
const StudentID = styled.p`
	font-size: 6px;
	margin: 0 auto;

	margin-top: 5px;
	color: #424648;
	text-transform: uppercase;
	font-weight: 700;
	font-family: product_sansbold;
	text-overflow: ellipsis;
`;
const FormContainer = styled.div`
	display: flex;
	margin: 50px auto;
	border: 1px solid #b5e2f0;
	background-color: #f5fbfd;
	border-radius: 20px;
	padding: 30px;
	flex-direction: column;
	width: 80%;
`;
const DetailsHead = styled.span`
	display: block;
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	margin: 10px 0 20px;
`;
const Form = styled.div`
	text-transform: capitalize;
	font-weight: 500;
	margin: 20px 0;
`;
const StudentInput = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;
const InputName = styled.p``;
const PhotoContainer = styled.div`
	display: flex;
	align-items: center;
	width: 64%;
`;
const InputField = styled.input`
	padding: 15px;
	width: 64%;
	color: rgb(0, 0, 0);
	font-size: 17px;
	padding-left: 15px;
	caret-color: rgb(92, 198, 106);
	background-color: #fff;
	border: 1px solid #b5e2f0;
	border-radius: 5px;
`;
const Generate = styled.button`
	font-size: 18px;
	font-weight: 500;
	margin: 10px auto 10px;
	display: block;
	padding: 15px 60px;
	background: #9fdbed;
	border: 1px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		background: #b5e2f0;
		border: 1px solid #b5e2f0;
		transition-duration: 0.1s;
	}
`;
const Download = styled.button`
	padding: 20px 100px;
	border-radius: 10px;
	font-size: 18px;
	cursor: pointer;
	font-family: "product_sansbold";
	background: #d1e8e0;
	border: 1px solid #d1e8e0;
	margin: 30px 0 50px;
`;
const Upload = styled.input``;
const RenderIdCard = styled.div`
	margin: 0 auto;
`;
